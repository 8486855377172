html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  box-sizing: inherit;

}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, hgroup, menu, nav, section {
  display: block;

}

html {
}
body {
  line-height: 1.43;

  background-color: #ffffff;
  overscroll-behavior: none;
  letter-spacing: 0.01071em;
  font-weight: 400;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
  color: rgba(51, 51, 51, 1);
  font-size: 1rem;
  height:100%;
  margin:0;
  }




.App {
  text-align: center;
}




.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #000;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.box {
  border: 6px solid #ffffff;
  color: #ffffff;
  padding: 20px 30px;
  font-size: 2rem;
  font-weight: bold;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  width: 300px;
  background-color: rgba(0, 0, 0, 0.4);
  align-items: center;
  display: flex;
}

.box_mobile {
  border: 3px solid #ffffff;
  color: #ffffff;
  padding: 20px 30px;
  font-size: 1.2rem;
  font-weight: bold;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  width: 200px;
  background-color: rgba(0, 0, 0, 0.3);
  align-items: center;
  display: flex;
}

.NavigationBar{
   width: 100%;
   position: sticky;
    top: 0px;
    background-color: #ffffff;
    z-index: 5;
    display: flex;

}

.NavigationBarMobile{
  width: 100%;
  position: sticky;
   top: 0px;
   background-color: #ffffff;
   z-index: 5;
   display: flex;
   height: 56px;

}
